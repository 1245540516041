import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // console.log("Sidebar");
    this.sidebarElement = this.element;
    this.sidebarContent = this.sidebarElement.querySelector(".sidebar-content");
    this.closeButtons = this.sidebarElement.querySelectorAll(".close-sidebar");

    // Agregar un manejador de clic al documento
    document.addEventListener("click", function (event) {
      const turboFrame = document.querySelector("#sidebar");
      const elementToCheck = document.querySelector(".sidebar-content"); // Cambia esto al selector de tu elemento específico

      if (elementToCheck && !elementToCheck.contains(event.target)) {
        // turboFrame.innerHTML = "";
        // console.log("Se hizo clic fuera del elemento.");
        if (turboFrame) {
          // Eliminar el Turbo Frame
          turboFrame.innerHTML = "";
        }
      }
    });
  }

  activarSegundoSidebar(e) {
    e.stopPropagation();
    e.preventDefault();
    // console.log("Botón:", e.currentTarget);

    const boton = e.currentTarget;
    const content = boton
      .closest(".filter_section")
      .querySelector(".sidebar-content");
    // console.log("Contenido:", content);

    if (content.classList.contains("oculto")) {
      content.classList.remove("oculto");
    } else {
      content.classList.add("oculto");
    }
  }

  closeTurboFrame(e) {
    e.stopPropagation();
    e.preventDefault();
    const turboFrame = document.querySelector("#sidebar");

    if (turboFrame) {
      // Eliminar el Turbo Frame
      turboFrame.innerHTML = "";
    }
  }
}
