import { Controller } from "@hotwired/stimulus";
// import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ["ciudadSelect"];
  static values = {
    cookies: Object
  }
  baseUrl = document.querySelector('meta[name="base-url"]').getAttribute('content');

  connect() {
    // console.log("¡Hola desde nuestro primer controlador Stimulus!");
    // console.log("COOKIES ccess", Cookies.get('access_token'));
    // console.log("cookies profile", this.cookiesValue);
  }

  seleccionarCiudad() {
    this.provinciaSelectInternacion = document.getElementById("provinciaSelect");
    this.provinciaSelectEfector = document.getElementById("provinciaSelect2");
    // const estados = JSON.parse(provinciaSelect.dataset.estados);

    if (this.provinciaSelectInternacion) {
      this.provinciaSeleccionada = this.provinciaSelectInternacion.value;
    } else if (this.provinciaSelectEfector) {
      this.provinciaSeleccionada = this.provinciaSelectEfector.value;
    }
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${Cookies.get('access_token')}`,
        'role-type': this.cookiesValue.type,
        'entity-id': this.cookiesValue.entity_id,
        'owner': this.cookiesValue.owner
      },
    };
    fetch(
      `${this.baseUrl}/estados/${this.provinciaSeleccionada}/ciudades`, options
    )
    .then((response) => response.json())
    .then((data) => {
      const divCiudades = document.getElementById("ciudad");
      if (divCiudades.tomselect) {
        divCiudades.tomselect.destroy();
      }
      divCiudades.classList.remove(
        "icon-select",
        "form-control",
        "input-paciente-datos"
      );
      if (data.status_code != 404) {
        this.ciudadeslist = [];
        data.forEach((city) => {
          this.ciudadeslist.push(
            `<option value='${city["id"]}' id='${city["id"]}'>${city["nombre"]}</option>`
          );
        })
        this.options = this.ciudadeslist.join("");
      } else {
        this.ciudadeslist = `<option value=''>No hay ciudades</option>`
        this.options = this.ciudadeslist;
      }
      let string = `
        <select class="form-select icon-select select optional custom-placeholder-dark"
                prompt= "Seleccionar ciudad" autocomplete="new-password"
                id="nuevo_paciente_domicilio_id_admin_param_ciudad"
                name="paciente[domicilio][admin_param_ciudad][id]">
          <option value="">Seleccione ciudad</option>
            ${this.options}
        </select>`;
      divCiudades.innerHTML = `<div class='d-flex w-100 mb-3' id="nuevo_paciente_domicilio_id_admin_param_ciudad">${string}</div>`;
      this.tomSelect(`#ciudad`);

      // Find the select with ts-wrapper class and do not add the class edit-icon-input
      let selects = document.querySelectorAll('.ts-wrapper');
      if (!selects) {
        return;
      }
      selects.forEach(select => {
        select.classList.remove("edit-icon-input");
      });

      // Find the input inside the select and add the class custom-placeholder-dark
      let input = document.querySelector('#ciudad-ts-control')
      if (!input) {
        return;
      }
      input.classList.add("custom-placeholder-dark");
    });
  }

  tomSelect(id) {
    this.tomSelectInstancenew = new TomSelect(id, {
      create: false,
      sortField: {
        field: "text",
        direction: "asc",
      },
    });
  }

  blanqueoPorPais() {
    this.ciudad = document.getElementById('ciudad');
    var paisSelectInt = document.getElementById('internacion_pais');
    var paisSelectRfector = document.getElementById('pais_efector');
    this.provinciaSelectInternacion = document.getElementById("provinciaSelect");
    this.provinciaSelectEfector = document.getElementById("provinciaSelect2");
    this.ciudad = document.getElementById("ciudad");
    if (this.provinciaSelectInternacion) {
      this.ciudad.disabled = (paisSelectInt.value === "");
      this.provinciaSelectInternacion.disabled = (paisSelectInt.value === "");
      this.provinciaSelectInternacion.value = "";
    } else if (this.provinciaSelectEfector) {
      this.ciudad.disabled = (paisSelectRfector.value === "");
      this.provinciaSelectInternacion.disabled = (paisSelectRfector.value === "");
      this.provinciaSelectEfector.value = "";
    }
    console.log(this.tomSelectInstancenew)
    if (this.tomSelectInstancenew) {
      if(paisSelectInt.value === "") {
        this.tomSelectInstancenew.setValue("");
        this.tomSelectInstancenew.clearOptions();
      };
    }
  }
}
