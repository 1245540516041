import { Controller } from "@hotwired/stimulus";
import { clone } from "o";

export default class extends Controller {
  static targets = [
    "listaRecursos",
    "addRecurso",
    "addInsumo",
    "listaInsumos",
    "listaEquipamientos",
    "addEquipamiento",
    "addServicio",
    "addModuloPrestador",
    "numeroAutorizacion",
    "fechaAutorizacion"
  ];

  static values = {
    recursos: Object,
    profesiones: Object,
    insumos: Object,
    equipamientos: Object,
    servicio: Object,
    token: String,
    cookies: Object,
    baseurl: String,
    personalizado: String,
    modulo: Object

  }
  connect() {
    if (this.element.dataset.edit == 'true') {
      var template = document.querySelector("#template-edit");
    } else {
      var template = document.querySelector("#template");
    }
    if (template) {
      // console.log('this.personalizado', this.personalizadoValue)
      if (this.personalizadoValue != 'true') {
        // console.log('paso por aca')
        this.notAddServiceDiv = true
        this.addServicio()
      }
    }


  }



  addRecurso() {
    if (this.addRecursoTarget.dataset.rcount){
      this.recursosCount  = this.addRecursoTarget ?  parseInt(this.addRecursoTarget.dataset.rcount) : 0
    } else {
      this.recursosCount = 0
    }
    console.log('RECURSOS VALUE', this.recursosValue)
    let index = this.addRecursoTarget.childElementCount + 1 + this.recursosCount;
    let optionsHtml = Object.entries(this.recursosValue).map(([key, value]) =>
      `<option value="${value}">${key}</option>`
    ).join('\n');
    optionsHtml = `<option value="">Seleccionar especialidad</option>\n` + optionsHtml;

    let optionsProfesiones = Object.entries(this.profesionesValue).map(([key, value]) =>
      `<option value="${value}">${key}</option>`
    ).join('\n');
    optionsProfesiones = `<option value="">Seleccionar profesión</option>\n` + optionsProfesiones;
    // const optionsRecursosHTML = Array.from(this.listaRecursosTarget.options)
    //   .map((option) => option.outerHTML)
    //   .join("");
    this.addRecursoTarget.insertAdjacentHTML(
      "beforeend",
      `
    <div class="recursos" id='add-recurso_${index}' data-controller='form-element'>
        <div class="row">
          <div class='col-6'>
            <div class="w-100">
              <div class='mt-2 mb-1'>
                <label class="label-paciente-datos" for="modulo_nuevo_recurso_${index}_profesion">Profesion</label>
              </div>
              <select style="font-size: 13px;" class="" id="lista-profesiones-${index}" data-controller= 'tom-select' data-add-items-target="listaProfesiones" name="modulo[nuevo_recurso_${index}][profesion_id]" create="false" data-idprofe= 'lista-recursos-${index}' data-action= 'change->form-element#remoteProfEspe'}>${optionsProfesiones}</select>
            </div>
          </div>
          <div class='col-6'>
            <div class="w-100">
              <div class='mt-2 mb-1 align-items-center w-100 d-flex justify-content-between'>
                <label class="label-paciente-datos" for="modulo_nuevo_recurso_${index}_tipo_recuso">Especialidad(Opcional)</label>
                <i style="font-size: 1.5rem; color: black; border-radius: 50px; cursor: pointer;" class="fa-regular fa-circle-xmark" onclick="document.getElementById('add-recurso_${index}').remove()"></i>
              </div>
              <select style="font-size: 13px; border-radius: 0.375rem;" class="w-100" id="lista-recursos-${index}" data-add-items-target="listaRecursos" data-controller='tom-select' data-action="tom-select:refresh->tom-select#refresh"  name="modulo[nuevo_recurso_${index}][especialidad_id]" create="false"><option value="">Seleccione especialidad</option></select>
            </div>
          </div>
        </div>
        <a href="/modulos/new" id='submitbtn' class="d-none" data-turbo-stream="true" data-form-element-target="submitbtn">update especialidades</a>

        <div class="row">
          <div class="col-4" data-controller='inputs'>
            <div class="w-100">
              <label class="label-paciente-datos" for="modulo_tiempo_visita_${index} (hs)">Tiempo de visita (min) (Opcional)</label>
              <input style="font-size: 13px;" class="form-control" data-action='input->inputs#updateMins' type="number" name="modulo[nuevo_recurso_${index}][tiempo]" id="modulo_tiempo_visita_nuevo_recurso_${index}" min="0" placeholder='Minutos'>
            </div>
            <div id="horas-min" class='label-paciente-datos text-end pe-3'></div>
          </div>
          <div class="col-4">
            <div class="w-100" >
              <label class="label-paciente-datos" for="modulo_frecuencia_${index}">Frecuencia</label>
              <input style="font-size: 13px;" class="form-control" type="number" name="modulo[nuevo_recurso_${index}][cantidad_frecuencia]" id="modulo_frecuencia_nuevo_recurso_${index}">
            </div>
          </div>
          <div class="col-4">
            <div class="w-100" >
              <label class="label-paciente-datos" for="modulo_visita_por_${index}">Visita por</label>
              <select style="font-size: 13px;" class="icon-select form-control input-paciente-datos" name="modulo[nuevo_recurso_${index}][frecuencia]" id="modulo_visita_por_${index}"><option value="Seleccionar">Seleccionar</option>
              <option value="Diario">Diario</option>
              <option value="Semanal">Semanal</option>
              <option value="Mensual">Mensual</option></select>
            </div>
          </div>
        </div>
      </div>`
    );
  }

  closeRecurso(event) {
    let index = document.getElementById("nueva_practica").childElementCount;
    document.getElementById(`practica_${index}`).remove();
  }

  numeroAutorizacion(event) {
    var checkbox = event.target;
    var index = checkbox.dataset.index;
    const campoNumero = checkbox.parentElement.parentElement.parentElement.querySelector("#internacion_numero_autorizacion");
    var fechaAutorizacion = checkbox.parentElement.parentElement.parentElement.parentElement.querySelector('.fecha-autorizacion');
    if (checkbox.checked) {
      campoNumero.value = 'En trámite';
      campoNumero.style.pointerEvents = 'none';
      fechaAutorizacion.disabled = true;
    } else {
      campoNumero.value = '';
      campoNumero.style.pointerEvents = 'auto';
      fechaAutorizacion.disabled = false;
    }
  }

  addInsumo() {
    if (this.addInsumoTarget.dataset.icount){
      this.insumosCount  = this.addInsumoTarget ?  parseInt(this.addInsumoTarget.dataset.icount) : 0
    } else {
      this.insumosCount = 0
    }
    let index = this.addInsumoTarget.childElementCount + 1 + this.insumosCount;
    let optionsHtml = Object.entries(this.insumosValue).map(([key, value]) =>
      `<option value="${value}">${key}</option>`
    ).join('\n');
    optionsHtml = `<option value="">Seleccionar insumo</option>\n` + optionsHtml;

    this.addInsumoTarget.insertAdjacentHTML(
      "beforeend",
      `
        <div id='add-insumo_${index}'>

          <div class="mt-2 w-100 d-flex justify-content-end">
            <i style="font-size: 1.5rem; color: black; border-radius: 50px; cursor: pointer;" class="fa-regular fa-circle-xmark" onclick="document.getElementById('add-insumo_${index}').remove()"></i>
          </div>

          <div class="d-flex w-100 flex-row gap-3">
            <div class="w-100" >
              <label class="label-paciente-datos" for="modulo_tipo_insumo">Tipo de insumo</label>
              <select style="font-size: 13px;" class="" data-controller='tom-select' name="modulo[nuevo_insumo_${index}][tipo_insumo_id]" id="modulo_tipo_insumo_${index}">${optionsHtml}</select>
            </div>
            <div class="w-100" >
              <label class="label-paciente-datos" for="modulo_cantidad">Cantidad de insumo</label>
              <input type="number" style="font-size: 13px;" class="form-control" type="text" name="modulo[nuevo_insumo_${index}][cantidad]" id="modulo_cantidad_insumo_${index}">
            </div>
            <div class="w-100" >
              <label class="label-paciente-datos" for="modulo_por">Por</label>
              <select style="font-size: 13px;" class="icon-select form-control input-paciente-datos" name="modulo[nuevo_insumo_${index}][frecuencia]" id="modulo_por_${index}"><option value="Seleccionar">Seleccionar frecuencia</option>
              <option value="Diario">Diario</option>
              <option value="Semanal">Semanal</option>
              <option value="Mensual">Mensual</option></select>
            </div>
          </div>
        </div>
      `
    );
  }

  closeInsumo(event) {
    let index = document.getElementById("nueva_practica").childElementCount;
    document.getElementById(`practica_${index}`).remove();
  }

  addEquipamiento() {
    if (this.addEquipamientoTarget.dataset.ecount){
      this.equipamientosCount  = this.addEquipamientoTarget ?  parseInt(this.addEquipamientoTarget.dataset.ecount) : 0
    } else {
      this.equipamientosCount = 0
    }
    let index = this.addEquipamientoTarget.childElementCount + 1 + this.equipamientosCount;
    let optionsHtml = Object.entries(this.equipamientosValue).map(([key, value]) =>
        `<option value="${value}">${key}</option>`
      ).join('\n');
    optionsHtml = `<option value="">Seleccionar recurso</option>\n` + optionsHtml;
    this.addEquipamientoTarget.insertAdjacentHTML(
      "beforeend",
      `
        <div id='add-equipamiento_${index}'>
          <div class="mt-2 w-100 d-flex justify-content-end">
            <i style="font-size: 1.5rem; color: black; border-radius: 50px; cursor: pointer;" class="fa-regular fa-circle-xmark" onclick="document.getElementById('add-equipamiento_${index}').remove()"></i>
          </div>
          <div class="d-flex w-100 flex-row gap-3">
            <div class="w-100">
              <div class='w-100 d-flex justify-content-between'>
                <label class="label-paciente-datos" for="modulo_equipamiento">Equipamiento</label>
              </div>
              <select style="font-size: 13px;" class="mt-1" data-controller='tom-select' data-add-items-target="listaEquipamientos" name="modulo[nuevo_equipamiento_${index}][tipo_equipamiento_id]" id="modulo_equipamiento_${index}">${optionsHtml}</select>
            </div>
            <div class="w-100">
              <label class="label-paciente-datos" for="modulo_cantidad">Cantidad de equipamiento</label>
              <input type="number" style="font-size: 13px;" class="form-control" type="text" name="modulo[nuevo_equipamiento_${index}][cantidad]" id="modulo_cantidad_equipamiento_${index}">
            </div>
            <div class="d-none" style="border-radius: 80px; position: relative">
              <label class "label-paciente-datos" for "modulo_frecuencia">Frecuencia</label>
              <select style="font-size: 13px;" class="icon-select form-control input-paciente-datos" name="modulo[nuevo_equipamiento_${index}][frecuencia]" id="modulo_frecuencia_${index}">
                <option value="Seleccionar">Seleccionar</option>
                <option value="Diario">Diario</option>
                <option value="Semanal">Semanal</option>
                <option value="Mensual">Mensual</option>
              </select>
            </div>
          </div>
        </div>
      `
    );
  }

  closeEquipamiento(event) {
    let index = document.getElementById("nueva_practica").childElementCount;
    document.getElementById(`practica_${index}`).remove();
  }

  tomSelect(id) {
    new TomSelect(id, {
      create: false,
    });
  }

  onFormChange(event) {
    if (event.target.value != 'create_custom_module') {

      const elements = Array.from(document.querySelectorAll('.service-target'));
      const boxes = Array.from(document.querySelectorAll('.modulo-prestador-box'));

      let box = boxes[elements.indexOf(event.currentTarget)]
      document.querySelectorAll(".grupo-modulo-prestador").forEach((grupo, index) => {
        var selectedModules = document.querySelectorAll('#internacion_modulo');
        var selectedModules = document.querySelectorAll('[data-attr1="value"]')
        var selectedOptionIndex = selectedModules[index].selectedIndex;
        if (selectedOptionIndex) {
          var selectedOptionText = selectedModules[index].options[parseInt(selectedOptionIndex, 10)];
          grupo.querySelector(".modulo-name").innerText = selectedOptionText.text;

          var selectedModuleDetail = selectedOptionText.dataset;
          grupo.querySelector(".modulo-recursos").innerHTML = ""
          JSON.parse(selectedModuleDetail.recursos).forEach((recurso) => {
            grupo.querySelector(".modulo-recursos").insertAdjacentHTML("beforeend", `
              <div class="modulo-recurso d-flex justify-content-between">
                <div class="recurso-name py-3 col-3">Profesión: <span class='ms-3 text-secondary'>${recurso[4]}</span></div>
                <div class="recurso-name py-3 col-3">Especialidad: <span class='ms-3 text-secondary'>${recurso[0] ?? "Sin asignar"}</span></div>
                <div class="cantidad py-3 col-2">Cantindad: <span class='ms-3 text-secondary'>${recurso[1]}</span></div>
                <div class="frecuencia py-3 col-2">Por: <span class='ms-3'>${recurso[2]}</span></div>
                <div class="duracion py-3 col-3">Duración: <span class='ms-3 text-secondary'>${recurso[3]}</span></div>
              </div>
            `)
          })
          grupo.querySelector(".modulo-insumos").innerHTML = ""

          JSON.parse(selectedModuleDetail.insumos).forEach((insumo) => {
            grupo.querySelector(".modulo-insumos").insertAdjacentHTML("beforeend", `
              <div class="modulo-insumo d-flex justify-content-between">
                <div class="insumo-name py-3 col-4">Tipo:<span class='ms-3 text-secondary'>${insumo[2]}</span></div>
                <div class="cantidad py-3 col-4">Cantidad:<span class='ms-3 text-secondary'>${insumo[1]}</span></div>
                <div class="frecuencia py-3 col-4">Por:<span class='ms-3 text-secondary'>${insumo[0]}</span></div>
              </div>
            `)
          })
          grupo.querySelector(".modulo-equipamientos").innerHTML = ""

          JSON.parse(selectedModuleDetail.equipamiento).forEach((equipamiento) => {
            grupo.querySelector(".modulo-equipamientos").insertAdjacentHTML("beforeend", `
              <div class="equipamiento-name py-3">Tipo:<span class='ms-3 text-secondary'>${equipamiento[0]}</span></div>
            `)
          })
        }
      })

      document.querySelectorAll(".prestador-name").forEach((provider, index) => {
        var selectedProviders = document.querySelectorAll('#prestador-modulo');
        var selectedOptionIndex = selectedProviders[index].selectedIndex;
        if (selectedOptionIndex) {
          var selectedOptionText = selectedProviders[index].options[parseInt(selectedOptionIndex, 10)].text;
          provider.innerText = selectedOptionText;
        }
      })

      let internacion_horario_visita = event.currentTarget.querySelector("#internacion_horario_visita").value
      box.querySelector("#dato-visita").innerText = internacion_horario_visita

      let internacion_fecha_inicio = event.currentTarget.querySelector('#fecha-inicio').value;
      box.querySelector("#dato-fecha-inicio").innerText = internacion_fecha_inicio

      let internacion_fecha_finalizacion = event.currentTarget.querySelector('#fecha-finalizacion').value;
      box.querySelector("#dato-fecha-finalizacion").innerText = internacion_fecha_finalizacion

      let internacion_numero_autorizacion = event.currentTarget.querySelector('#internacion_numero_autorizacion').value;
      box.querySelector("#dato-autorizacion").innerText = internacion_numero_autorizacion

      let internacion_fecha_autorizacion = event.currentTarget.querySelector('#internacion_fecha_autorizacion').value;
      box.querySelector("#dato-fecha-autorizacion").innerText = internacion_fecha_autorizacion
    }

  }

  addServiceResume() {
    const elements = document.querySelectorAll('.service-target');

    var datoServicio = document.getElementById('dato-servicio');
    datoServicio.insertAdjacentHTML(
      "beforeend",
      `
        <div class="modulo-prestador-box border rounded mb-3" id="boxService${elements.length}">
          <div class="service-number ps-3 py-3">
            <p class="p-overview my-0">Servicio ${elements.length}:</p>
          </div>
          <div class="">
            <div class="fechas d-flex py-3 border-bottom-resumen px-3">
              <div class='col-6'>
                <span class="p-overview">Fecha de inicio del servicio:</span> <span class="p-overview text-secondary ms-4" id="dato-fecha-inicio"></span>
              </div>
              <div class="col-6">
                <span class="p-overview">Fecha de finalización del servicio:</span> <span class="p-overview text-secondary ms-4" id="dato-fecha-finalizacion"></span>
              </div>
            </div>
            <div class="authorizacion d-flex py-3 border-bottom-resumen px-3">
              <div class="col-6">
                <span class="p-overview">Autorización:</span><span class="ms-4 p-overview text-secondary" id="dato-autorizacion"></span>
              </div>
              <div class="col-6">
                <span class="p-overview">Fecha de autorizacion: <span class="ms-4 p-overview text-secondary" id="dato-fecha-autorizacion"></span></span>
              </div>
            </div>
            <div class="grupo-modulo-prestador">
              <div style='background: #F1F3FF;' class='py-3'>
                <span class="p-overview ps-3">Modulo:</span>
                <span class="modulo-name ms-4 text-secondary"></span>
              </div>
              <div class='border-bottom-resumen p-3'>
                <span class="p-overview">Prestador:</span>
                <span class="prestador-name ms-4 text-secondary"></span>
              </div>
              <div class="p-3 border-bottom-resumen">
                <span class="p-overview">Recursos:</span>
                <div class="modulo-recursos">
                </div>
              </div>
              <div class="p-3 border-bottom-resumen">
                <span class="p-overview">Insumos:</span>
                <div class="modulo-insumos">
                </div>
              </div>
              <div class="p-3 border-bottom-resumen">
                <span class="p-overview">Equipamientos:</span>
                <div class="modulo-equipamientos">
                </div>
              </div>
            </div>


            <div class="horario-visita border-bottom-resumen p-3">
              <span class="p-overview">Horario de visita sugerida:</span><span class="ms-3 span-overview text-secondary" id="dato-visita"></span>
            </div>
          </div>
        </div>
      `
    )
  }


  addServicio() {
    // console.log(this.element)
    if (document.querySelectorAll('.service-target').length > 0) {
      var indexServicio = document.querySelectorAll('.service-target').length + 1 ;
    } else {
      var indexServicio = 1;
    }
    if (this.element.dataset.edit == 'true') {
      var template = document.querySelector("#template-edit");
    } else {
      var template = document.querySelector("#template");
    }
    const clone = template.content.cloneNode(true);
    clone.querySelector('#fecha-inicio').name = `internacion[servicio_${indexServicio}][fecha_inicio_servicio]`
    clone.querySelector('#fecha-finalizacion').name = `internacion[servicio_${indexServicio}][fecha_final_servicio]`
    clone.querySelector('#internacion_numero_autorizacion').name = `internacion[servicio_${indexServicio}][numero_autorizacion]`
    clone.querySelector('#internacion_fecha_autorizacion').name = `internacion[servicio_${indexServicio}][fecha_autorizacion]`
    // console.log("clone.querySelector('#internacion_horario_visita')", clone.querySelector('#internacion_horario_visita'))
    clone.querySelector('#internacion_horario_visita').name = `internacion[servicio_${indexServicio}][horario_visita]`
    clone.querySelector('#check-tramite-internacion').dataset.indexServicio = indexServicio
    if (this.element.dataset.edit == 'true') {
      clone.querySelector('#fecha-inicio').name = `internacion[servicio_edit${indexServicio}][fecha_inicio_servicio]`
      clone.querySelector('#fecha-finalizacion').name = `internacion[servicio_edit${indexServicio}][fecha_final_servicio]`
      clone.querySelector('#internacion_numero_autorizacion').name = `internacion[servicio_edit${indexServicio}][numero_autorizacion]`
      clone.querySelector('#internacion_fecha_autorizacion').name = `internacion[servicio_edit${indexServicio}][fecha_autorizacion]`
      // console.log("clone.querySelector('#internacion_horario_visita')", clone.querySelector('#internacion_horario_visita'))
      clone.querySelector('#internacion_horario_visita').name = `internacion[servicio_edit${indexServicio}][horario_visita]`
      clone.querySelector('#fecha-inicio').value = this.servicioValue.f_desde
      clone.querySelector('#fecha-finalizacion').value = this.servicioValue.f_hasta
      clone.querySelector('#internacion_numero_autorizacion').value = this.servicioValue.num_autorizacion
      clone.querySelector('#internacion_fecha_autorizacion').value = this.servicioValue.f_autorizacion
      clone.querySelector('#insertar-botoncerrar').setAttribute('data-turbo-frame', `servicio_prestador_${this.servicioValue.id}`);

      if (clone.querySelector('#internacion_numero_autorizacion').value == 'En trámite') {
        clone.querySelector('#check-tramite-internacion').checked = true
        clone.querySelector('#internacion_fecha_autorizacion').disabled = true
      }
      clone.querySelector('#internacion_horario_visita').value = this.servicioValue.horario_sugerido
      indexServicio = `edit${indexServicio}`
      this.servicioValue.servicios_modulos.forEach((servicio_modulo, index) => {
        this.addModuloPrestadorTemplateEdit(clone, indexServicio, servicio_modulo, index)
      });
      clone.querySelector('.titulo-datos').innerText = `Editar servicio: ${this.servicioValue.codigo}`

    } else {
      this.addModuloPrestadorTemplate(clone, indexServicio)
      clone.querySelector('.titulo-datos').innerText = `Nuevo servicio`

    }
    clone.querySelector('.template-content').id = `template-content-${indexServicio}`
    clone.querySelector(`#template-content-${indexServicio}`).classList.add('nuevoDiv')
    if (this.addServicioTarget) {
      if (this.addServicioTarget.childElementCount > 0) {
        clone.querySelector('.insertar-botoncerrar').innerHTML = `<i style="font-size: 1.5rem; color: black; border-radius: 50px; cursor: pointer; color:  #004A97;" class="boton-cerrar fa-regular fa-circle-xmark"></i>`
        clone.querySelector('.boton-cerrar').setAttribute('onclick', `document.getElementById('template-content-${indexServicio}').remove(); document.getElementById("boxService${indexServicio}").remove()`);
      }
      this.addServicioTarget.appendChild(clone);
      const newDiv = this.addServicioTarget.querySelector(`#template-content-${indexServicio}`);
      if (newDiv) {
          newDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }

  addModuloPrestadorTemplate(cloneGrande, index, indexModuloPrestador = 1) {


    if (this.element.dataset.edit == 'true') {
      var templateModuloPrestador = document.querySelector("#modulo-prestador-edit");
    } else {
      var templateModuloPrestador = document.querySelector("#modulo-prestador");

    }
    const cloneModuloPrestador = templateModuloPrestador.content.cloneNode(true);
    (this.element.dataset.edit == 'true') ? cloneModuloPrestador.querySelector('#servicio_modulo_id').remove() : ''
    cloneModuloPrestador.querySelector('.modulo-prestador-content').id= `modulo-prestador-content-${indexModuloPrestador}`
    var submitBtn = cloneModuloPrestador.querySelector('.submit-proveedores')
    submitBtn.setAttribute('data-turbo-frame', `prestadores-${index}${indexModuloPrestador}`)
    submitBtn.id= `submit-proveedores-${indexModuloPrestador}`
    submitBtn.href=`/internaciones/actualizar_prestadores_por_modulo?prestadores=prestadores-${index}${indexModuloPrestador}&name=internacion[servicio_${index}][prestador_${indexModuloPrestador}]`
    // cloneModuloPrestador.querySelector('#m_personalizado').setAttribute('id', `m_personalizado-${index}${indexModuloPrestador}`);
    cloneModuloPrestador.querySelector('#prestadores').setAttribute('id', `prestadores-${index}${indexModuloPrestador}`);
    cloneModuloPrestador.querySelector('#internacion_modulo').name = `internacion[servicio_${index}][modulo_${indexModuloPrestador}]`
    cloneModuloPrestador.querySelector('#internacion_modulo').setAttribute('data-modulo', `selector_${indexModuloPrestador}${index}`)
    cloneModuloPrestador.querySelector('#internacion_modulo').setAttribute('data-attr1', `value`)
    cloneModuloPrestador.querySelector('#internacion_modulo').setAttribute('data-prestaprovee', `selector_prestadores_proveedores_${indexModuloPrestador}${index}`)
    cloneModuloPrestador.querySelector('#internacion_modulo').classList.add(`selector_${indexModuloPrestador}${index}`)
    cloneModuloPrestador.querySelector('#internacion_modulo').setAttribute('id', `internacion_modulo-${index}${indexModuloPrestador}`);
    console.log('IDDDD addModuloPrestadorTemplate ', cloneModuloPrestador.querySelector(`#internacion_modulo-${index}${indexModuloPrestador}`))
    const elemento = cloneModuloPrestador.querySelector(`#internacion_modulo-${index}${indexModuloPrestador}`)
    const dataController = elemento.getAttribute('data-controller');
    console.log('DATA CONTROLLER', dataController)
    // Verifica si el 'data-controller' contiene 'tom-select'
    if (dataController && dataController.includes('tom-select')) {
      // console.log('El elemento ya tiene el controlador tom-select');
    } else {
      // Si no lo tiene, lo agrega, manteniendo otros controladores si ya existen
      const newDataController = dataController ? `${dataController} tom-select` : 'tom-select';
      elemento.setAttribute('data-controller', newDataController);
      const dataAction = elemento.getAttribute('data-action') || '';
      if (!dataAction.includes('tom-select:refresh->tom-select#refresh')) {
        // Si no lo tiene, agrega la nueva acción, conservando las existentes
        const newDataAction = dataAction ? `${dataAction} tom-select:refresh->tom-select#refresh` : 'tom-select:refresh->tom-select#refresh';
        elemento.setAttribute('data-action', newDataAction);
        console.log('Acción tom-select:refresh->tom-select#refresh agregada');
      }
    }
    cloneModuloPrestador.querySelector('#prestador-modulo').name = `internacion[servicio_${index}][prestador_${indexModuloPrestador}]`
    cloneModuloPrestador.querySelector('#prestador-modulo').classList.add(`selector_prestadores_proveedores_${indexModuloPrestador}${index}`)
    cloneModuloPrestador.querySelector('.agregar-modulo-prestador').dataset.index = index
    if (indexModuloPrestador > 1) {
      const nuevoIcono = document.createElement("i");
      nuevoIcono.classList.add("centrar-elemento", "align-items-center", "col-1", "fa-regular", "fa-circle-xmark", 'pt-2');
      // nuevoIcono.style.fontSize = "15px";
      nuevoIcono.style.color = 'black'

      nuevoIcono.setAttribute('style', 'position: absolute;right: 0;top: -10px;right: -7px; font-size: 15px');
      nuevoIcono.setAttribute('onclick', `this.closest('#modulo-prestador-content-${indexModuloPrestador}').remove(); document.querySelector('#group_${indexModuloPrestador}').remove()`);
      cloneModuloPrestador.querySelector('.agregar-modulo-prestador').replaceWith(nuevoIcono)
    }
    cloneGrande.querySelector('#modulo-prestador-list').appendChild(cloneModuloPrestador);
  }
  addModuloPrestadorTemplateEdit(clone, index, servicio_modulo, indexModuloPrestador = 1) {
    // index = parseInt(index, 10) + 1
    const templateModuloPrestador = document.querySelector("#modulo-prestador-edit");
    const cloneModuloPrestador = templateModuloPrestador.content.cloneNode(true);
    cloneModuloPrestador.querySelector('.modulo-prestador-content').id= `modulo-prestador-content-${indexModuloPrestador}`
    var submitBtn = cloneModuloPrestador.querySelector('.submit-proveedores')
    submitBtn.setAttribute('data-turbo-frame', `prestadores-${index}${indexModuloPrestador}`)
    submitBtn.id= `submit-proveedores-${indexModuloPrestador}`
    submitBtn.href=`/internaciones/actualizar_prestadores_por_modulo?prestadores=prestadores-${index}${indexModuloPrestador}&name=internacion[servicio_${index}][prestador_${indexModuloPrestador}]`
    cloneModuloPrestador.querySelector('#prestadores').setAttribute('id', `prestadores-${index}${indexModuloPrestador}`);
    cloneModuloPrestador.querySelector('#internacion_modulo').name = `internacion[servicio_${index}][modulo_${indexModuloPrestador}]`
    cloneModuloPrestador.querySelector('#internacion_modulo').setAttribute('data-modulo', `selector_${indexModuloPrestador}${index}`)
    cloneModuloPrestador.querySelector('#internacion_modulo').setAttribute('data-attr1', `value`)
    cloneModuloPrestador.querySelector('#internacion_modulo').classList.add(`selector_${indexModuloPrestador}${index}`)
    cloneModuloPrestador.querySelector('#internacion_modulo').setAttribute('data-prestaprovee', `selector_prestadores_proveedores_${indexModuloPrestador}${index}`)
    cloneModuloPrestador.querySelector('#internacion_modulo').setAttribute('id', `internacion_modulo-${index}${indexModuloPrestador}`);
    cloneModuloPrestador.querySelector(`#internacion_modulo-${index}${indexModuloPrestador}`).setAttribute('data-controller', 'tom-select');
    cloneModuloPrestador.querySelector(`#internacion_modulo-${index}${indexModuloPrestador}`).setAttribute('data-action', 'tom-select:refresh->tom-select#refresh');
    console.log('IDDDD addModuloPrestadorTemplateeDIT ', cloneModuloPrestador.querySelector(`#internacion_modulo-${index}${indexModuloPrestador}`))
    cloneModuloPrestador.querySelector('#servicio_modulo_id').name = `internacion[servicio_${index}][servicio_modulo_${indexModuloPrestador}]`
    cloneModuloPrestador.querySelector('#servicio_modulo_id').classList.add(`selector_prestadores_proveedores_${indexModuloPrestador}${index}`)
    cloneModuloPrestador.querySelector('#servicio_modulo_id').value = `${servicio_modulo.id}`
    if (servicio_modulo.prestador_id != null) {
      var servidor = servicio_modulo.prestador_id
      var clave = 'prestador'
    }
    if (servicio_modulo.proveedor_id != null) {
      var servidor = servicio_modulo.proveedor_id
      var clave = 'proveedor'
    }
    let prestadorModulo = cloneModuloPrestador.querySelector('#prestador-modulo')
    cloneModuloPrestador.querySelector('#prestador-modulo').name = `internacion[servicio_${index}][prestador_${indexModuloPrestador}]`
    if (servicio_modulo.prestador_id == null) {
      this.prestador = servicio_modulo.proveedor_id
    } else {
      this.prestador = servicio_modulo.prestador_id
    }
    this.replaceSelectPrestadores(prestadorModulo, servicio_modulo.modulo_id, this.prestador)
    // Aca actualizo la lista de prestadores segun el modulo que le haya tocado
    cloneModuloPrestador.querySelector('#prestador-modulo').value = `${clave}-${servidor}`
    cloneModuloPrestador.querySelector('.agregar-modulo-prestador').dataset.index = index
    if (indexModuloPrestador > 0) {
      const nuevoIcono = document.createElement("i");
      nuevoIcono.setAttribute('style', 'position: absolute;right: 0;top: -10px;right: -7px; font-size: 15px');
      nuevoIcono.classList.add("centrar-elemento", "align-items-center", "col-1", "fa-regular", "fa-circle-xmark", 'pt-2');
      // nuevoIcono.style.fontSize = "15px";
      nuevoIcono.style.color = 'black'
      nuevoIcono.setAttribute('onclick', `this.closest('#modulo-prestador-content-${indexModuloPrestador}').remove()`);
      cloneModuloPrestador.querySelector('.agregar-modulo-prestador').replaceWith(nuevoIcono)
    }
    cloneModuloPrestador.querySelector(`#internacion_modulo-${index}${indexModuloPrestador}`).value = `${servicio_modulo.modulo_id}`
    clone.querySelector('#modulo-prestador-list').appendChild(cloneModuloPrestador);
  }

  addModuloPrestador(e) {
    var listaModPrest = e.target.closest('#modulo-prestador-list')
    var indexModuloPrestador = listaModPrest.childElementCount + 1
    var indexServicio = e.target.dataset.index
    const boxes = Array.from(document.querySelectorAll('.modulo-prestador-box'));
    if (boxes.length > 0) {
      boxes[indexServicio - 1].querySelectorAll(".grupo-modulo-prestador")[boxes[indexServicio - 1].querySelectorAll(".grupo-modulo-prestador").length - 1].insertAdjacentHTML(
        "afterend",
        `
          <div class="grupo-modulo-prestador" id="group_${indexModuloPrestador}">
            <div style='background: #F1F3FF;' class='py-3'>
              <span class="p-overview ps-3">Modulo:</span>
              <span class="modulo-name ms-4"></span>
            </div>
            <div class='border-bottom-resumen p-3'>
              <span class="p-overview">Prestador:</span>
              <span class="prestador-name ms-3"></span>
            </div>
            <div class="p-3 border-bottom-resumen">
              <span class="p-overview">Recursos:</span>
              <div class="modulo-recursos">
              </div>
            </div>
            <div class="p-3 border-bottom-resumen">
              <span class="p-overview">Insumos:</span>
              <div class="modulo-insumos">
              </div>
            </div>
            <div class="p-3 border-bottom-resumen">
              <span class="p-overview">Equipamientos:</span>
              <div class="modulo-equipamientos">
              </div>
            </div>
          </div>
        `
      )
    }
    var servicio = document.getElementById(`template-content-${indexServicio}`)
    this.addModuloPrestadorTemplate(servicio, indexServicio, indexModuloPrestador)
  }

  replaceSelectPrestadores(clone, modulo_id, prestadorSelectedId = '') {
    fetch(`${this.baseurlValue}/modulo/${modulo_id}/prestador-proveedor`, {
      method: 'GET', // o 'POST', 'PUT', 'DELETE', etc. dependiendo de tu caso
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.tokenValue}`,
        'role-type' : this.cookiesValue.type,
        'entity-id': this.cookiesValue.entity_id,
        'owner': this.cookiesValue.owner
      }
    })
      .then(response => response.json())
      .then(data => {
        function createOptgroup(label, items) {
          const optgroup = document.createElement('optgroup');
          optgroup.label = label;

          items.forEach(item => {
            const option = document.createElement('option');
            option.value = `${label}-${item.id}`;
            option.textContent = item.nombre;
            // console.log('ITEM.ID', item.id)
            if (item.id === prestadorSelectedId) {
              option.selected = true;
            }
            optgroup.appendChild(option);
          });
          return optgroup;
        }

        clone.innerHTML = ''
        // Crear y agregar el optgroup para prestadores
        const prestadoresOptgroup = createOptgroup('prestador', data.prestadores);
        clone.appendChild(prestadoresOptgroup);

        // Crear y agregar el optgroup para proveedores
        const proveedoresOptgroup = createOptgroup('proveedor', data.proveedores);
        clone.appendChild(proveedoresOptgroup);
        return clone
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }
}
